export const staffTypeNavigation = () => {
  return [
    [
      {
        name: "doctor",
        label: "I am a doctor",
        img: "doctor",
        value: "doctor",
      },
      {
        name: "nurse",
        label: "I am a nurse",
        img: "nurse",
        subtitle: "Regular Nurse / Relievers",
        value: "nurse",
      },
    ],
  ]
}
