import React, { useState } from "react"
import classNames from "classnames"

import styles from "./utils/layout.module.scss"
import {
  hasSeenNotification,
  setNotificationAsSeen,
} from "./services/notification"

const Notification = ({ pageContext }) => {
  const [notificationHidden, setNotificationHidden] = useState(false)
  const hasSeenNotificationMessage = hasSeenNotification()

  let notificationMessage = ""
  if (notificationMessage && !hasSeenNotificationMessage)
    return (
      <div
        className={classNames(
          "notification is-warning",
          {
            "is-hidden": notificationHidden,
          },
          styles["notification"]
        )}
      >
        <button
          aria-label="Delete Button"
          className="delete"
          onClick={() => {
            setNotificationHidden(true)
            setNotificationAsSeen()
          }}
        ></button>
        <div className="has-text-centered">{notificationMessage}</div>
      </div>
    )

  return null
}

export default Notification
