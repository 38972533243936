import React, { useState } from "react"
import { navigate } from "gatsby"
import Layout from "layout"
import Container from "layout/Container"

import CardButtons from "../../Elements/CardButtons"

import Button from "elements/Button"

import { staffTypeNavigation } from "../utils/staffTypeNavigation"

const Deputy = ({ pageContext }) => {
  const [selectedStaffType, setSelectedStaffType] = useState(null)

  const handleClick = (data) => {
    setSelectedStaffType(data)
  }
  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.seoTitle}
      pageContext={pageContext}
      isPrivate
    >
      <Container isCentered desktop={6} fullhd={6}>
        <CardButtons
          buttons={staffTypeNavigation()}
          handleClick={handleClick}
        />

        <div className="has-text-centered">
          <Button
            className="mt-4 has-text-centered"
            color="primary"
            type="submit"
            isFullwidth
            isDisabled={!selectedStaffType}
            onClick={() => {
              navigate("/deputy/select")
            }}
          >
            {pageContext.module.cta || "Confirm"}
          </Button>
        </div>
      </Container>
    </Layout>
  )
}

export default Deputy
