import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"

import Img from "gatsby-image"
import classNames from "classnames"

import styles from "./utils/elements.module.scss"
import useCardButtonImages from "./hooks/useCardButtonImages"

const CardButtons = ({ buttons, handleClick }) => {
  const data = useCardButtonImages()

  const [activeCardIndex, setActiveCardIndex] = useState("")

  const handleSetClick = ({ index, buttonList }) => {
    setActiveCardIndex(index)
  }

  const isCardActive = ({ index }) => {
    return index === activeCardIndex
  }

  return (
    <div>
      {buttons?.map((buttonList, index) => {
        return (
          <div className="columns is-centered m-0 mt-3">
            {buttonList?.map((button, buttonIndex) => {
              return (
                <div classname="column">
                  <div
                    className={classNames(
                      "is-flex is-justify-content-center",
                      styles["cardLinkContainer__card"]
                    )}
                  >
                    <button
                      className={classNames(
                        "is-block mr-1 mr-0-mobile mt-1-mobile",
                        styles["cardLinkContainer"]
                      )}
                      onClick={() => {
                        handleSetClick({ index: buttonIndex })
                        if (handleClick) handleClick(button)
                      }}
                    >
                      <div
                        className={classNames(
                          "has-text-centered",
                          styles["cardLinkContainer__cardButton"],
                          styles[
                            `cardLinkContainer__cardButton__${
                              isCardActive({ index: buttonIndex })
                                ? "active"
                                : null
                            }`
                          ]
                        )}
                      >
                        {isCardActive({ index: buttonIndex }) ? (
                          <div
                            className={classNames(
                              "has-text-white is-vcentered",
                              styles[`circleCheck`]
                            )}
                          >
                            <FontAwesomeIcon icon={faCheck} />
                          </div>
                        ) : null}
                        <div className="pt-2">
                          <Img
                            fixed={data[button?.img]?.childImageSharp?.fixed}
                          />
                          <div className="card-content">
                            <div className="content is-size-6">
                              <p className={styles["cardLinkContainer__label"]}>
                                {button?.label}
                              </p>
                              {button?.subtitle && (
                                <p className="has-text-grey-light is-size-6">
                                  {button?.subtitle}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default CardButtons
